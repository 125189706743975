export const BASE_API = "";

export const ROUTER_URL = {
  root: {
    name: "root",
    link: "",
    title: "WizFit Challenge",
    children: {
      landingPage: {
        name: "landingPage",
        link: "",
        title: "WizFit Challenge",
      },
      addNewSchool:{
        name: "addNewSchool",
        link: "/add-School",
        title: "Add School | Wizfit Challenge"
      },
     mediaCredentials:{
        name: "mediaCredentials",
        link: "/media-credentials",
        title: "Media Credentials | Wizfit Challenge"
      },
      wizfitchallenge:{
        name: "wizfitchallenge",
        link: "/wizfitchallenge",
        title: "School List| Wizfit Challenge"
      },
      emailConfirmation:{
        name: "emailConfirmation",
        link: "/mail/email_notification_confirmation/",
        title: "Email Confirmed | Wizfit Challenge"
      },
      smsConfirmation:{
        name: "smsConfirmation",
        link: "/mail/sms_notification_confirmation/",
        title: "SMS Confirmed | Wizfit Challenge"
      },
      districtPublicpage:{
        name: "districtPublicpage",
        link: "/riverhead-central-school-district",
        title: "School List| Wizfit Challenge"
      },
    },
  },
  auth: {
    name: "auth",
    link: "",
    title: "WizFit Challenge",
    children: {
      login: {
        name: "login",
        link: "/login",
        title: "Login | WizFit Challenge",
      },
      forgotPassword: {
        name: "forgotPassword",
        link: "/forgot-password",
        title: "Forgot Password | WizFit Challenge",
      },
      resetPassword: {
        name: "resetPassword",
        link: "/reset-password",
        title: "Reset Password | WizFit Challenge",
      },
    },
  },
  adminPanel: {
    name: "adminPanel",
    link: "",
    title: "Admin | WizFit Challenge",
    children: {
      dashboard: {
        name: "dashboard",
        link: "/dashboard",
        title: "Dashboard | WizFit Challenge",
      },
      districtManagement: {
        name: "districtManagement",
        link: "/district-management",
        title: "District Management | WizFit Challenge",
      },
      schoolManagement:{
        name: "schoolManagement",
        link: "/school-management",
        title: "School Management | Wizfit Challenge"
      },
      campaignManagement:{
        name: "campaignManagement",
        link: "/campaign-management",
        title: "CampaignPanel Management | Wizfit Challenge"
      },
      newCampaign:{
        name: "newCampaign",
        link: "/new-campaign",
        title: "New CampaignPanel | Wizfit Challenge"
      },
      teacherManagement:{
        name: "teacherManagement",
        link: "/teacher-management",
        title: "Teacher Management | Wizfit Challenge"
      },
      studentManagement:{
        name: "studentManagement",
        link: "/student-management",
        title: "Student Management | Wizfit Challenge"
      },
      sponsorManagement:{
        name: "sponsorManagement",
        link: "/sponsor-management",
        title: "Sponsor Management | Wizfit Challenge"
      },
      teamManagement:{
        name: "teamManagement",
        link: "/team-management",
        title: "Team Management | Wizfit Challenge"
      },
      calendarManagement:{
        name: "calendarManagement",
        link: "/calendars-management",
        title: "Calendar Management | Wizfit Challenge"
      },
      listcalendarManagement:{
        name: "listcalendarManagement",
        link: "/listcalendars-management",
        title: "Calendar Management | Wizfit Challenge"
      },
      playerListCalendar:{
        name: "playerListCalendar",
        link: "/playerListCalendar-management",
        title: "Calendar Management | Wizfit Challenge"
      },
      resourceManagement:{
        name: "resourceManagement",
        link: "/resources-management",
        title: "Resource Management | Wizfit Challenge"
      },
     gameresourceManagement:{
        name: "gameresourceManagement",
        link: "/gameresources-management",
        title: "Game Resource Management | Wizfit Challenge"
      },
      gameresourceUpload:{
        name: "gameresourceUpload",
        link: "/gameresourceupload-management",
        title: "Game Resource Management | Wizfit Challenge"
      },
      resourceCommonUpload:{
        name: "resourceCommonUpload",
        link: "/resourcescommonupload-management",
        title: "Resource Management | Wizfit Challenge"
      },
      onboardingManagement:{
        name: "onboardingManagement",
        link: "/onboarding-management",
        title: "Onboarding Campaign Management | Wizfit Challenge"
      },
      nongameeventManagement: {
        name: "nongameeventManagement",
        link: "/nongameevent-management",
        title: "Non Game Management | WizFit Challenge",
      },
      hotelManagement: {
        name: "hotelManagement",
        link: "/hotelManagement-management",
        title: "Hotel Management | WizFit Challenge",
      },
      assemblyManagement: {
        name: "assemblyManagement",
        link: "/assemblyManagement",
        title: "Assembly Management | WizFit Challenge",
      },
      daycalendarManagement:{
        name: "daycalendarManagement",
        link: "/daycalendars-management",
        title: "Calendar Management | Wizfit Challenge"
      },
      weekcalendarManagement:{
        name: "weekcalendarManagement",
        link: "/weekcalendars-management",
        title: "Calendar Management | Wizfit Challenge"
      },
      donationManagement:{
        name: "donationManagement",
        link: "/donation-management",
        title: "Donation Management | Wizfit Challenge"
      },
      bulkUploadDistrict:{
        name:"bulkUploadDistrict",
        link:"/district/bulk-upload",
        title:"Bulk Upload District | Wizfit Challenge"
      },
      bulkUploadSchool:{
        name:"bulkUploadSchool",
        link:"/school/bulk-upload",
        title:"Bulk Upload School | Wizfit Challenge"
      },
      prizeManagement:{
        name:"prizeManagement",
        link:"/prize-management",
        title:"Prize Management | Wizfit Challenge"
      },
      emailSettings:{
        name:"emailSettings",
        link:"/email",
        title:"Email Settings | Wizfit Challenge"
      },
      automatedEmailForm:{
        name:"automatedEmailForm",
        link:"/automated-edit",
        title:"Edit Automated Email | Wizfit Challenge"
      },
      manualEmailForm:{
        name:"manualEmailForm",
        link:"/manual-email",
        title:"Manual Email | Wizfit Challenge"
      },
      bulkEmailForm:{
        name:"bulkEmailForm",
        link:"/bulk-email",
        title:"Bulk Email | Wizfit Challenge"
      },
      gameManagement:{
        name:"gameManangement",
        link:"game-management",
        title:"Game Management | Wizfit Challenge"
      },
      needhelp:{
        name: "needhelp",
        link: "/need-help",
        title: "Student Management | Wizfit Challenge"
      },
      gamecsvUpload:{
        name: "gamecsvUpload",
        link: "/gamecsvUpload-management",
        title: "Game Upload Management | Wizfit Challenge"
      },
     
    },
  },
  districtPanel: {
    name: "districtPanel",
    link: "/district/",
    title: "District | WizFit Challenge",
    children: {
      dashboard: {
        name: "districtDashboard",
        link: "dashboard",
        title: "District Dashboard | WizFit Challenge",
      },
      schoolManagement:{
        name: "districtSchoolManagement",
        link: "school-management",
        title: "School Campaigns | Wizfit Challenge"
      },
      teacherManagement:{
        name: "districtTeacherManagement",
        link: "teacher-management",
        title: "Teacher Management | Wizfit Challenge"
      },
      studentManagement:{
        name: "districtStudentManagement",
        link: "student-management",
        title: "Student Management | Wizfit Challenge"
      },
      sponsorManagement:{
        name: "districtSponsorManagement",
        link: "sponsor-management",
        title: "Sponsor Management | Wizfit Challenge"
      },
      donationManagement:{
        name: "districtDonationManagement",
        link: "donation-management",
        title: "Donation Management | Wizfit Challenge"
      },
      teamManagement:{
        name: "districtTeamManagement",
        link: "team-management",
        title: "Team Management | Wizfit Challenge"
      },
      emailSettings:{
        name:"districtEmailSettings",
        link:"email",
        title:"Email Settings | Wizfit Challenge"
      },
      todolist:{
        name: "schoolToDoManagement",
        link: "todolist-management",
        title: "todolist Management | Wizfit Challenge"
      },
      hosttodolist:{
        name: "hostschoolToDoManagement",
        link: "hosttodolist-management",
        title: "hosttodolist Management | Wizfit Challenge"
      },
      campaigncalendar:{
        name: "campaignCalendar",
        link: "campaigncalendar-management",
        title: "campaignCalendar | Wizfit Challenge"
      },
      needhelp:{
        name: "districtneedhelp",
        link: "need-help",
        title: "Student Management | Wizfit Challenge"
      },
    },
  },
  schoolPanel: {
    name: "schoolPanel",
    link: "/school/",
    title: "School | WizFit Challenge",
    children: {
      dashboard: {
        name: "schoolDashboard",
        link: "dashboard",
        title: "School Dashboard | WizFit Challenge",
      },
      teacherManagement:{
        name: "schoolTeacherManagement",
        link: "teacher-management",
        title: "Teacher Management | Wizfit Challenge"
      },
      studentManagement:{
        name: "schoolStudentManagement",
        link: "student-management",
        title: "Student Management | Wizfit Challenge"
      },
      donationManagement:{
        name: "schoolDonationManagement",
        link: "donation-management",
        title: "Donation Management | Wizfit Challenge"
      },
      sponsorManagement:{
        name: "schoolSponsorManagement",
        link: "sponsor-management",
        title: "Sponsor Management | Wizfit Challenge"
      },
      teamManagement:{
        name: "schoolTeamManagement",
        link: "team-management",
        title: "Team Management | Wizfit Challenge"
      },
      bulkUploadTeacher:{
        name:"bulkUploadTeacher",
        link:"/teacher/bulk-upload",
        title:"Bulk Upload Teacher | Wizfit Challenge"
      },
      emailSettings:{
        name:"schoolEmailSettings",
        link:"email",
        title:"Email Settings | Wizfit Challenge"
      },
      galleryManagement:{
        name:"schoolGalleryManagement",
        link:"gallery-management",
        title:"Gallery Management | Wizfit Challenge"
      },
      todolist:{
        name: "schoolToDoManagement",
        link: "todolist-management",
        title: "todolist Management | Wizfit Challenge"
      },
      hosttodolist:{
        name: "hostschoolToDoManagement",
        link: "hosttodolist-management",
        title: "hosttodolist Management | Wizfit Challenge"
      },
      campaigncalendar:{
        name: "campaignCalendar",
        link: "campaigncalendar-management",
        title: "campaignCalendar | Wizfit Challenge"
      },
      resource:{
        name: "resource",
        link: "resource-management",
        title: "resource | Wizfit Challenge"
      },
      resourceupload:{
        name: "resourceupload",
        link: "resource-upload-management",
        title: "resources | Wizfit Challenge"
      },
      campaignGame:{
        name: "campaignGame",
        link: "campaign-game-management",
        title: "campaignGame | Wizfit Challenge"
      },
      needhelp:{
        name: "schoolneedhelp",
        link: "need-help",
        title: "Student Management | Wizfit Challenge"
      },
    },
  },
  schoolLandingPage:{
    name: "schoolLandingPage",
    link: "/school/landing/",
    title: "School | WizFit Challenge",
  },
  gamePanel:{
    name: "gamePanel",
    link: "/game/",
    title: "Games | WizFit Challenge",
    children:{
      dashboard: {
        name: "gameDashboard",
        link: "dashboard",
        title: "Game Dashboard | WizFit Challenge",
      },
      gamecalendar:{
        name:"gamecalendar",
        link:"gamecalendar",
        title:"Game Calendar | WizFit Challenge"
      },
      gamehostassembly:{
        name:"gamehostassembly",
        link:"gamehostassembly",
        title:"Game Host Assembly | WizFit Challenge"
      },
      gamehostlist:{
        name:"gamehostlist",
        link:"gamehostlist",
        title:"Game Host Assembly | WizFit Challenge"
      },
      gameresource:{
        name:"gameresource",
        link:"gameresource",
        title:"Game Resource | WizFit Challenge"
      },
      marketingResources:{
        name:"marketingResources",
        link:"marketing-resources",
        title:"Marketing Resources | WizFit Challenge"
      },
      gamecommittee:{
        name:"gamecommittee",
        link:"gamecommittee",
        title:"Game Host Committee | WizFit Challenge"
      },
      gameticketupdate:{
        name:"gameticketupdate",
        link:"gameticketupdate",
        title:"Game Host Ticket Update | WizFit Challenge"
      },
      gameallassembly:{
        name:"gameallassembly",
        link:"gameallassembly",
        title:"Game Host Ticket Update | WizFit Challenge"
      },
      gameemail:{
        name:"gameemail",
        link:"gameemail",
        title:"Game Email | WizFit Challenge"
      },
      bulkGameEmailForm:{
        name:"bulkGameEmailForm",
        link:"/bulkgame-email",
        title:"Bulk Email | Wizfit Challenge"
      },
    }
  },
  teacherPanel: {
    name: "teacherPanel",
    link: "/teacher/",
    title: "Teacher | WizFit Challenge",
    children: {
      dashboard: {
        name: "teacherDashboard",
        link: "dashboard",
        title: "Teacher Dashboard | WizFit Challenge",
      },
      studentManagement:{
        name: "teacherStudentManagement",
        link: "student-management",
        title: "Student Management | Wizfit Challenge"
      },
      galleryManagement:{
        name:"teacherGalleryManagement",
        link:"gallery-management",
        title:"Gallery Management | Wizfit Challenge"
      },
      needhelp:{
        name: "teacherneedhelp",
        link: "need-help",
        title: "Student Management | Wizfit Challenge"
      },

    },
  },
  peteacherPanel:{
    name:"peteacherPanel",
    link:"/pe-teacher/",
    title:"PE Teacher | WizFit Challenge",
    children:{
      dashboard:{
        name:"peteacherDashboard",
        link:"dashboard",
        title:"PE Teacher Dashboard | WizFit Challenge"
      }
    }
  },
  principalPanel:{
    name:"principalPanel",
    link:"/principal/",
    title:"Principal | WizFit Challenge",
    children:{
      dashboard:{
        name:"principalDashboard",
        link:"dashboard",
        title:"Principal Dashboard | WizFit Challenge"
      }
    }
  },
  publicDonationPages:{
    name: "publicDonationPages",
    link: "",
    title: "WizFit Challenge",
    // children: {
    //   publicLandingPage: {
    //     name: "publicLandingPage",
    //     link: "",
    //     title: "WizFit Challenge",
    //   },
    // },
  },
  publicParentDashboard:{
    name: "publicParentDashboard",
    link: "/parent",
    title: "WizFit Challenge",
    // children: {
    //   publicLandingPage: {
    //     name: "publicLandingPage",
    //     link: "",
    //     title: "WizFit Challenge",
    //   },
    // },
  },
  errorPage: {
    name: "error",
    link: "/error/",
    title: "Error",
    children: {
      error404: {
        name: "notFound",
        link: "404/",
        title: "Page Not Found",
      },
      error401: {
        name: "authenticationError",
        link: "401/",
        title: "Authentication Error",
      },
      error500: {
        name: "internalServer",
        link: "500/",
        title: "Internal Server Error",
      },
    },
  },
};

export const API_COMMON_URL = {
  login: BASE_API + "/users/login/",
  createUser: BASE_API + "/users/create_user/",
  verifyEmail: BASE_API + "/users/verify_account/",
  resendOTP: BASE_API + "/users/resend_otp/",
  forgotPassword: BASE_API + "/users/forgot_password/",
};

export default {
  ROUTER_URL,
  API_COMMON_URL,
};
